const fields = [

  "attempted",
"correct",
"errors",
];

const Column_Row_Fields = [
  "",
  "Name",
 ...fields
];

export { Column_Row_Fields, fields };
