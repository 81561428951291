function Spinner() {
	return (
		<div className="text-center my-4">
			<div className="spinner-border" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export { Spinner };
