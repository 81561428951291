import { Minus, Plus } from 'lucide-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert, Button, Container, Tab, Tabs
} from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Select from 'react-select';
import { errorToast, successToast } from '../../utils';
import AxiosConfig from '../../utils/axiosConfig';
import { grades, sections, subjects } from "../../utils/globals";
import { Spinner } from '../spinner';

// Initial states
const initialMarkingScheme = [{ questionNumber: '1', numberOfMarks: '10' }];
const initialSelectionState = { grade: null, section: null, subject: null };

// Utility functions
const createFieldName = (pupilId, questionNumber) => `pupil_${pupilId}_${questionNumber}`;

const EotScreen = () => {
  // Router hooks
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Redux state
  const { selectedSchool } = useSelector((state) => state.school);

  // Component state
  const [selection, setSelection] = useState(initialSelectionState);
  // const [_, setSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('create');

  // Data state
  const [pupils, setPupils] = useState([]);
  const [eotPupils, setEOTPupils] = useState([]);
  const [markings, setMarkings] = useState([]);
  const [fields, setFields] = useState({});

  // UI state
  const [isCreatingMarkingScheme, setIsCreatingMarkingScheme] = useState(false);
  const [markingScheme, setMarkingScheme] = useState(initialMarkingScheme);

  // URL parameters handling
  const updateURLParams = useCallback((newParams) => {
    const currentParams = new URLSearchParams(location.search);
    Object.entries(newParams).forEach(([key, value]) => {
      if (value) {
        currentParams.set(key, value);
      } else {
        currentParams.delete(key);
      }
    });
    navigate({
      pathname: location.pathname,
      search: currentParams.toString()
    }, { replace: true });
    // eslint-disable-next-line
  }, [location.pathname, navigate]);

  // Selection handlers
  const handleSelectionChange = useCallback((field, option) => {
    setSelection(prev => ({ ...prev, [field]: option }));
    updateURLParams({ [field]: option?.value });
    // eslint-disable-next-line
  }, [updateURLParams]);

  // Data fetching
  const fetchData = useCallback(async () => {
    const { grade, section, subject } = selection;
    if (!grade?.value || !section?.value || !subject?.value || !selectedSchool._id) return;

    setLoading(true);
    setError('');

    try {
      const [pupilsRes, markingsRes, eotRes] = await Promise.all([
        AxiosConfig.post('/pupil/getbysectiongrade', {
          student_section: section.value,
          grade: grade.value,
          school_id: selectedSchool._id
        }),
        AxiosConfig.post('/markingcsch/all', {
          student_section: section.value,
          grade: grade.value,
          subject: subject.value,
          school_id: selectedSchool._id,
        }),
        AxiosConfig.post('/eotSch/all', {
          grade: grade.value,
          subject: subject.value,
          student_section: section.value,
        })
      ]);

      setPupils(pupilsRes.data);
      setMarkings(markingsRes.data[0]?.rows || []);
      setEOTPupils(eotRes.data);

      // Convert existing EOT data to fields format
      if (eotRes.data.length > 0) {
        const existingFields = {};
        eotRes.data.forEach(pupil => {
          existingFields[pupil.pupil_id._id] = {};
          Object.entries(pupil.marks).forEach(([questionKey, value]) => {
            const fieldName = createFieldName(pupil.pupil_id._id, questionKey.replace('Q', ''));
            existingFields[pupil.pupil_id._id][fieldName] = value;
          });
        });
        setFields(existingFields);
      } else {
        setFields({});
      }

    } catch (err) {
      setError('Failed to fetch data. Please try again.');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [selection, selectedSchool._id]);

  // Marking scheme handlers
  // Remove the handleMarkingSchemeActions object from here

// Add this inside your component, before the render functions
const handleMarkingSchemeActions = useMemo(() => ({
  add: () => setMarkingScheme(prev => [
    ...prev,
    { questionNumber: String(prev.length + 1), numberOfMarks: '10' }
  ]),

  remove: (index) => setMarkingScheme(prev =>
    prev.filter((_, i) => i !== index)
  ),

  update: (index, field, value) => setMarkingScheme(prev => {
    const updated = [...prev];
    updated[index] = { ...updated[index], [field]: value };
    return updated;
  }),

  create: async () => {
    if (!selection.grade || !selection.section || !selection.subject) {
      setError('Please select grade, section, and subject');
      return;
    }

    setLoading(true);
    try {
      await AxiosConfig.post('/markingcsch/create', {
        grade: selection.grade.value,
        student_section: selection.section.value,
        subject: selection.subject.value,
        school_id: selectedSchool._id,
        rows: markingScheme
      });

      successToast('Marking scheme created successfully');
      setIsCreatingMarkingScheme(false);
      fetchData();
    } catch (err) {
      errorToast('Failed to create marking scheme');
    } finally {
      setLoading(false);
    }
  },

  delete: async () => {
    if (!selection.grade || !selection.section || !selection.subject) {
      setError('Please select grade, section, and subject');
      return;
    }

    setLoading(true);
    try {
      await AxiosConfig.post('/markingcsch/delete', {
        grade: selection.grade.value,
        student_section: selection.section.value,
        subject: selection.subject.value,
        school_id: selectedSchool._id,
      });

      successToast('Marking scheme deleted successfully');
      setMarkings([]);
      setIsCreatingMarkingScheme(false);
      setFields({});
    } catch (err) {
      errorToast(err.response?.status === 404 ?
        'Marking scheme not found' :
        'Failed to delete marking scheme'
      );
    } finally {
      setLoading(false);
    }
  }
}), [
  selection,
  selectedSchool._id,
  markingScheme,
  setError,
  setLoading,
  setMarkings,
  setIsCreatingMarkingScheme,
  setFields,
  fetchData
]);

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selection.grade || !selection.section || !selection.subject) {
      setError('Please select grade, section, and subject');
      return;
    }

    if (pupils.length === 0) {
      setError('No pupils available');
      return;
    }

    setLoading(true);
    try {
      const finalData = pupils.map((pupil) => ({
        pupil_id: pupil._id,
        subject: selection.subject.value,
        marks: markings.reduce((acc, marking) => {
          const fieldName = createFieldName(pupil._id, marking.questionNumber);
          acc[`Q${marking.questionNumber}`] = fields[pupil._id]?.[fieldName] || '0';
          return acc;
        }, {})
      }));

      // Delete existing data if it exists
      if (Object.keys(eotPupils).length > 0) {
        await AxiosConfig.post('/eotSch/delete', {
          grade: selection.grade.value,
          subject: selection.subject.value,
          student_section: selection.section.value,
        });
      }

      await AxiosConfig.post('/eotSch/create', finalData);
      successToast('EOT Assessment saved successfully');
      fetchData();
    } catch (err) {
      errorToast('An error occurred. Please try again');
    } finally {
      setLoading(false);
    }
  };

  // Initialize from URL params
  useEffect(() => {
    const params = ['grade', 'section', 'subject'].reduce((acc, param) => {
      const value = searchParams.get(param);
      if (value) {
        const options = { grade: grades, section: sections, subject: subjects }[param];
        const option = options.find(opt =>
          param === 'grade' ?
            parseInt(opt.value) === parseInt(value) :
            opt.value === value
        );
        if (option) acc[param] = option;
      }
      return acc;
      
    }, {});

    const tabParam = searchParams.get('tab');
    if (tabParam) setActiveTab(tabParam);

    if (Object.keys(params).length) {
      setSelection(prev => ({ ...prev, ...params }));
    }
    // eslint-disable-next-line
  }, []);

  // Fetch data when selection changes
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [fetchData]);

  // Memoized renderers
  const renderSelectors = useMemo(() => (
    <div className="d-flex gap-4 mb-4">
      {['grade', 'section', 'subject'].map(field => (
        <div key={field} className="w-25">
          <h3 className="mb-2">Select {field.charAt(0).toUpperCase() + field.slice(1)}</h3>
          <Select
            value={selection[field]}
            onChange={(option) => handleSelectionChange(field, option)}
            options={field === 'grade' ? grades : field === 'section' ? sections : subjects}
            isSearchable={false}
            className="basic-single"
            // onMenuOpen={() => setSelectOpen(true)}
            // onMenuClose={() => setSelectOpen(false)}
          />
        </div>
      ))}
    </div>
  ), [selection, handleSelectionChange]);

  const renderTables = useMemo(() => ({
    marking: () => (
      <div className="card p-4 mb-4">
        <h4 className="mb-3">Create Marking Scheme</h4>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Question Number</th>
                <th>Maximum Marks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {markingScheme.map((question, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={question.questionNumber}
                      onChange={(e) => handleMarkingSchemeActions.update(index, 'questionNumber', e.target.value)}
                      placeholder="Question number"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={question.numberOfMarks}
                      onChange={(e) => handleMarkingSchemeActions.update(index, 'numberOfMarks', e.target.value)}
                      placeholder="Max marks"
                      min="1"
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleMarkingSchemeActions.remove(index)}
                      disabled={markingScheme.length === 1}
                    >
                      <Minus size={16} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <Button onClick={handleMarkingSchemeActions.add} variant="secondary">
            <Plus size={16} className="mr-2" /> Add Question
          </Button>
          <Button onClick={handleMarkingSchemeActions.create} className="primaryButton">
            Save Marking Scheme
          </Button>
        </div>
      </div>
    ),

    create: () => {
      if (!pupils.length) {
        return (
          <div className="alert alert-info">
            Please select grade, section, and subject to view pupils
          </div>
        );
      }

      if (!markings.length && !isCreatingMarkingScheme) {
        return (
          <div className="text-center p-4">
            <p className="mb-3">No marking scheme found for the selected criteria</p>
            <Button
              onClick={() => setIsCreatingMarkingScheme(true)}
              className="primaryButton"
            >
              Create Marking Scheme
            </Button>
          </div>
        );
      }

      if (isCreatingMarkingScheme) {
        return renderTables.marking();
      }

      return (
        <div className="card">
          <div className="d-flex justify-content-between p-3">
            {Object.keys(eotPupils).length > 0 && (
              <Alert variant="info" className="mb-0">
                Existing assessment data loaded. Submit to update.
              </Alert>
            )}
            <Button
              variant="danger"
              onClick={handleMarkingSchemeActions.delete}
              className="mb-3"
            >
              Delete Marking Scheme
            </Button>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-nowrap">Student ID</th>
                  <th className="text-nowrap">Student Name</th>
                  {markings.map((marking) => (
                    <th key={marking.questionNumber} className="text-nowrap">
                      Q{marking.questionNumber} ({marking.numberOfMarks} marks)
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {pupils.map((pupil) => (
                  <tr key={pupil._id}>
                    <td className="text-nowrap">{pupil.student__nid}</td>
                    <td className="text-nowrap">
                      {`${pupil.student_firstname} ${pupil.student_lastname}`}
                    </td>
                    {markings.map((marking) => {
                      const fieldName = createFieldName(pupil._id, marking.questionNumber);
                      return (
                        <td key={`${pupil._id}_${marking.questionNumber}`}>
                          <input
                            type="number"
                            className="form-control"
                            name={fieldName}
                            value={fields[pupil._id]?.[fieldName] || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              const numValue = Number(value);
                              if (numValue < 0 || numValue > Number(marking.numberOfMarks)) return;
                              setFields(prev => ({
                                ...prev,
                                [pupil._id]: {
                                  ...prev[pupil._id],
                                  [fieldName]: value
                                }
                              }));
                            }}
                            min="0"
                            max={Number(marking.numberOfMarks)}
                            required
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    },

    list: () => {
      if (!eotPupils.length) {
        return (
          <div className="alert alert-info">
            No assessment records found for the selected criteria
          </div>
        );
      }

      return (
        <div className="card">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-nowrap">Student NID</th>
                  <th className="text-nowrap">Student Name</th>
                  {Object.keys(eotPupils[0].marks).map((questionKey) => (
                    <th key={questionKey} className="text-nowrap">
                      {questionKey}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {eotPupils.map((pupil) => (
                  <tr key={pupil.pupil_id._id}>
                    <td className="text-nowrap">{pupil.pupil_id.student__nid}</td>
                    <td className="text-nowrap">
                      {`${pupil.pupil_id.student_firstname} ${pupil.pupil_id.student_lastname}`}
                    </td>
                    {Object.keys(pupil.marks).map((questionKey) => (
                      <td key={`${pupil.pupil_id._id}-${questionKey}`} className="text-nowrap">
                        {pupil.marks[questionKey] || '-'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }), [pupils, markings, fields, eotPupils, isCreatingMarkingScheme, markingScheme, handleMarkingSchemeActions]);

  return (
    <Container className="py-4">
      <Toaster />
      <h2 className="mb-4">End Of Term Assessment</h2>

      {error && (
        <Alert variant="danger" className="mb-4">
          {error}
        </Alert>
      )}

      <Tabs
        activeKey={activeTab}
        onSelect={(k) => {
          setActiveTab(k);
          updateURLParams({ tab: k });
        }}
        className="mb-4"
      >
        <Tab eventKey="create" title="Create">
          <form onSubmit={handleSubmit}>
            {renderSelectors}

            {loading ? (
              <div className="text-center py-4">
                <Spinner />
              </div>
            ) : (
              <>
                {renderTables.create()}
                {pupils.length > 0 && markings.length > 0 && !isCreatingMarkingScheme && (
                  <div className="text-center mt-4">
                    <Button type="submit" className="primaryButton">
                      Submit Assessment
                    </Button>
                  </div>
                )}
              </>
            )}
          </form>
        </Tab>

        <Tab eventKey="list" title="List">
          {renderSelectors}

          {loading ? (
            <div className="text-center py-4">
              <Spinner />
            </div>
          ) : (
            renderTables.list()
          )}
        </Tab>
      </Tabs>
    </Container>
  );
};

export { EotScreen };
